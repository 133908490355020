import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-card-item',
    templateUrl: './card-button.component.html',
    styleUrls: ['./card-button.component.scss'],
    standalone: true,
})
export class CardButtonComponent {
    @Input() title = '';
}
